<template>
    <v-bottom-navigation horizontal  color="#eb3254" class="overflow-visible"
    grow>
        <v-btn to="/home">
            <v-icon>mdi-storefront</v-icon>
            {{ $t('home') }}
        </v-btn>
        <v-btn @click="openCart">
            <v-badge :content="cartItemCount" color="red" overlap>
                <v-icon>mdi-cart</v-icon>
            </v-badge>
            {{ $t('cart') }}
        </v-btn>
        <v-btn to="/connect">
            <v-icon>mdi-comment</v-icon>
            {{ $t('connectUs') }}
        </v-btn>
        <v-btn to="/about">
            <v-icon>mdi-information-variant-circle-outline</v-icon>
            {{ $t('info') }}
        </v-btn>
        
       
    </v-bottom-navigation>
</template>

<script>
import { useAppStore } from '@/stores/app';

export default {
    name: 'BottomNavigation',

    data() {
        return {
            appStore: useAppStore(),
        };
    },

    computed: {
        cartItemCount() {
            return this.appStore.cart.length;
        },
    },

    methods: {
        openCart() {
            this.$router.push({ name: 'CartPage' });

        },
    },
};
</script>
