const en = {

    home: "Home",
    category:"Category",
    info:"Info",
    order:"Order",
    searchProduct:"Search product",
    sortItem:"Sort Item",
    connectUs:"Connect With Us",

    pagination: {
      ariaLabel: {
        root: "Root",
      },
    },
    input:{
        clear:"Clear",
        prependAction:"prepend Action",
        appendAction:"append Action",
    },
    carousel:{
        next:"next",
        prev:"prev",

    },
    productImage:"Image",
    yourOrders:"Your Orders",
    productName:"Name",
    quantity:"Quantity",
    actions:"Price",
    totalprice:"Total Price",
    orderViaWhatsApp:"Order Via WhatsApp",
    delete:"Delete",
    cart:"Cart",


  };
  
  export default en;
  