// Utilities
import { defineStore } from 'pinia'
import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
    headers: {
      'Content-Type': 'application/json'
    }
  });
export const useAppStore = defineStore('app', {
    state: () => ({
        marketData: null,
        showSearch: false,
        searchQuery: '',
        finalfilteredProducts: [],
        cart: [],
      }),
      actions: {
        async fetchMarketData(marketId) {
          try {
            const response = await apiClient.get(`/markets/${marketId}`);
            this.marketData = response.data;
          } catch (error) {
            console.error("Failed to fetch market data:", error);
          }
        },
        setShowSearch(value) {
            this.showSearch = value;
        },
        setSearchQuery(query) {
            this.searchQuery = query;
        },
        setFilteredProducts(products) {
            this.finalfilteredProducts = products;
        },
        addToCart(product) {
            const existingProduct = this.cart.find(item => item.id === product.id);
            if (existingProduct) {
                // إذا كان المنتج موجودًا بالفعل، قم بزيادة الكمية
                existingProduct.quantity += 1;
            } else {
                // إذا لم يكن موجودًا، أضفه مع الكمية 1
                this.cart.push({ ...product, quantity: 1 });
            }
        },
        removeFromCart(productId) {
            this.cart = this.cart.filter(item => item.id !== productId);
        },
        updateCartQuantity(productId, quantity) {
            const product = this.cart.find(item => item.id === productId);
            if (product && quantity > 0) {
                product.quantity = quantity;
            } else if (product && quantity === 0) {
                this.removeFromCart(productId);
            }
        },
        clearCart() {
            this.cart = [];
        }
      },
      getters: {
        getMarketData: (state) => state.marketData,
        getCart: (state) => state.cart,
        cartTotal: (state) =>
            state.cart.reduce((total, item) => total + item.price * item.quantity, 0),
      },
    })