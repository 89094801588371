import HomePage from '../pages/Home.vue'
import connectPage from '../pages/connect.vue'
import AboutPage from '../pages/about.vue'
import indexPage from '../pages/index.vue'
// import CartPage from '../pages/CartPage.vue'


const menuRoutes = [

{
    path: '/',
    redirect: '/home',
    name: 'index',
    component: indexPage,
    children: [
        {
            path: '/home',
            name: 'home',
            component: HomePage
          },
          {
            path: '/connect',
            name: 'connect',
            component: connectPage
          },
          {
            path: '/about',
            name: 'about',
            component: AboutPage
          },
          {
            path: '/cart',
            name: 'CartPage',
            component: () => import('../pages/CartPage.vue'),
          },
      
    ]
},

 

]

export default menuRoutes;
