<template>
    <v-row v-if="marketData" class="px-4 pt-4" style="margin: 0;">
        <v-col cols="6">
            <v-row class="px-0" align="center">
                <v-col cols="auto" align="center">
                    <v-avatar size="45" class="mx-auto mb-2 border-sm" color="#ffff">
                        <v-img :src="apiUrl + marketData.info.logo" alt="Logo"></v-img>
                    </v-avatar>
                </v-col>
                <v-col cols="auto" align="center">
                    <h4 color="#eb3254">{{ marketData.info.name }}</h4>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="6" class="d-flex justify-end align-center">
            <v-btn v-if="!appStore.showSearch" class="ma-2" color="#eb3254" icon="mdi-magnify" @click="toggleSearch"
                size="small"></v-btn>
            
            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn icon v-bind="props" size="small">
                        <v-icon>mdi-translate</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="setLanguage('ar')">
                        <v-list-item-title>AR</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="setLanguage('en')">
                        <v-list-item-title>EN</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
    </v-row>
    <!-- Search  -->
    <v-row v-if="appStore.showSearch" class="px-4 pb-4" style="margin: 0;">
        <v-col>
            <v-text-field style="box-shadow: none;" class="elevation-0 border-0 rounded-xl" :loading="loading"
                v-click-outside="closeSearch" append-inner-icon="mdi-chevron-right" prepend-inner-icon="mdi-magnify"
                density="compact" :label="$t('searchProduct')" variant="solo" hide-details single-line
                v-model="appStore.searchQuery" @input="searchProducts">
            </v-text-field>
        </v-col>
    </v-row>
</template>

<script setup>
import { useLocale } from 'vuetify';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
const { current } = useLocale();

function setLanguage(locales) {
    current.value = locales;
    locale.value = locales;
    localStorage.setItem('locale', locales);
    console.log(current.value);
}
</script>

<script>
import { useAppStore } from '@/stores/app';

export default {
    name: 'AppBar',

    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL + '/',
            appStore: useAppStore(),
            loading: false,
            selectedCategory: "1",
            layoutIcon: 'mdi-view-list',
            singleProductLayout: true,
            singleProduct: true
        };
    },

    computed: {
        marketData() {
            return this.appStore.getMarketData;
        },
        cartItemCount() {
        return this.appStore.cart.length; // عدد الطلبات في السلة
    },

    },

    methods: {
        openCart() {
        this.$router.push({ name: 'CartPage' }); // الانتقال إلى صفحة السلة
    },
        toggleSearch() {
            this.appStore.setShowSearch(!this.appStore.showSearch);
            if (!this.appStore.showSearch) {
                this.appStore.setSearchQuery(''); // Clear search query when hiding search
                this.appStore.setFilteredProducts([]); // Clear filtered products
            }
        },
        closeSearch() {
            this.appStore.setShowSearch(false);
            this.appStore.setSearchQuery(''); // Clear search query when hiding search
            this.appStore.setFilteredProducts([]); // Clear filtered products
        },
        searchProducts() {
            if (this.appStore.searchQuery.trim() === '') {
                this.appStore.setFilteredProducts([]); // No search query, clear results
                return;
            }

            const currentLocale = localStorage.getItem('locale') || 'en'; // Default to 'en' if not set

            const filteredProducts = this.appStore.marketData.products.filter(product => {
                // Get the product name based on the current locale
                const productName = product[`name_${currentLocale}`] || '';
                return productName.toLowerCase().includes(this.appStore.searchQuery.toLowerCase());
            });

            this.appStore.setFilteredProducts(filteredProducts);
        },
        toggleLayout() {
            this.singleProductLayout = !this.singleProductLayout;
            this.singleProduct = !this.singleProduct;
            this.layoutIcon = this.singleProductLayout ? 'mdi-view-list' : 'mdi-view-module';
        },
    },

    watch: {
        marketData(newVal) {
            if (newVal && newVal.categories.length > 0 && !this.selectedCategory) {
                this.selectedCategory = newVal.categories[0].id;
            }
        }
    },

    mounted() {
        if (this.marketData && this.marketData.categories.length > 0) {
            this.selectedCategory = this.marketData.categories[0].id;
        }
    }
};
</script>
